<template>
    <div class="setting-page">
        <layout :header="$t('settingMenu')" @goback="$router.push('/')"
            :class="{'blur-page': showAlertError || showAlertSuccess || showSetPin || showConfirmPin}">
            <template v-slot:content>
                <v-col class="card-setting" cols="12">
                    <v-row class="setting-item px-2 py-2" dense>
                        <v-col cols="9" align-self="center">
                            <div class="title-his">{{$t('notification')}}</div>
                        </v-col>
                        <v-col cols="3" class="text-right" align-self="center">
                            <v-switch v-model="notificationReq" inset hide-details class="mt-0 switch-x" color="#9D662C"
                                @change="changeNotification">
                            </v-switch>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="card-setting" cols="12">
                    <v-row class="setting-item px-2 py-2" dense>
                        <v-col cols="9" align-self="center">
                            <div class="title-his">
                                <span v-if="$i18n.locale == 'en'">Language: English</span>
                                <span v-if="$i18n.locale == 'th'">ภาษา: ไทย</span>
                            </div>
                        </v-col>
                        <v-col cols="3" class="text-right" align-self="center">
                            <v-btn icon small @click="$router.push('/SetLanguage')">
                                <v-icon small color="#9D662C">arrow_forward_ios</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="card-setting" cols="12">
                    <v-row class="setting-item px-2 py-2" dense>
                        <v-col cols="9" align-self="center">
                            <div class="title-his">PINCODE</div>
                        </v-col>
                        <v-col cols="3" class="text-right" align-self="center">
                            <v-switch v-model="pinReq" inset hide-details class="mt-0 switch-x" color="#9D662C"
                                @change="changePinReq">
                            </v-switch>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="card-setting" cols="12">
                    <v-row class="setting-item px-2 py-2" dense>
                        <v-col cols="9" align-self="center">
                            <div class="title-his">{{$t('setNewPin')}}</div>
                        </v-col>
                        <v-col cols="3" class="text-right" align-self="center">
                            <v-btn icon small @click="showConfirmPin = true;">
                                <v-icon small color="#9D662C">arrow_forward_ios</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
        </layout>
        <loading v-show="loadingState"></loading>
        <alert type="error" header="ล้มเหลว !" :title="textErr" v-show="showAlertError" @done="Done"></alert>
        <alert type="success" header="สำเร็จ !" :title="'แก้ไข PINCODE เรียบร้อยแล้ว'" v-show="showAlertSuccess"
            @done="showAlertSuccess = false;"></alert>
        <pin-pad v-show="showConfirmPin" :backward="true" header="Please confirm your<br> Old PINCODE" :clear="clearPin"
            @pinEmit="verifyPin" @goback="showConfirmPin = false;"></pin-pad>
        <pin-pad v-show="showSetPin" :backward="true" header="Please set New PINCODE<br>(6-digit numeric password)"
            :clear="clearPin" @pinEmit="setPin" @goback="showSetPin=false;"></pin-pad>
    </div>
</template>

<script>
    import Layout from "../../components/layout_1";
    import Loading from "../../components/loading";
    import Alert from "../../components/alert";
    import Pinpad from "../../components/pinPad";
    export default {
        name: "Setting",
        components: {
            layout: Layout,
            loading: Loading,
            alert: Alert,
            "pin-pad": Pinpad,
        },
        data: () => ({
            pinReq: null,
            notificationReq: null,
            clearPin: false,
            loadingState: false,
            showAlertError: false,
            showAlertSuccess: false,
            showSetPin: false,
            showConfirmPin: false,
            textErr: "กรุณาเช็คการเชื่อมต่อ",
        }),
        methods: {
            Done() {
                this.showAlertError = false;
            },
            errAlert(msg) {
                this.clearPin = !this.clearPin;
                this.textErr = msg;
                this.showAlertError = true;
            },
            async setPin(val) {
                this.loadingState = true;
                try {
                    const config = {
                        headers: {
                            'x-parse-session-token': localStorage.getItem('sessionToken_casalapin')
                        }
                    }
                    let params = {
                        pin: val,
                    };
                    let response = await this.$axios.post(
                        "/set_new_pin",
                        params,
                        config
                    );
                    let status = response.data.status;
                    if (status == 200) {
                        this.showSetPin = false;
                        setTimeout(() => {
                            this.showAlertSuccess = true;
                        }, 200);
                        this.clearPin = !this.clearPin;
                    } else {
                        this.errAlert("ตั้ง PIN ไม่สำเร็จ <br> กรุณาลองใหม่อีกครั้ง");
                    }
                } catch (err) {
                    this.errAlert("กรุณาเช็คการเชื่อมต่อ");
                }
                this.loadingState = false;
            },
            async verifyPin(val) {
                this.loadingState = true;
                try {
                    const config = {
                        headers: {
                            'x-parse-session-token': localStorage.getItem('sessionToken_casalapin')
                        }
                    }
                    let params = {
                        pin: val,
                    };
                    let response = await this.$axios.post(
                        "/verify_pin",
                        params,
                        config
                    );
                    let verifyPin = response.data;
                    if (verifyPin.status == 200) {
                        this.showConfirmPin = await false;
                        await setTimeout(() => {
                            this.showSetPin = true;
                        }, 200);
                        this.clearPin = !this.clearPin;
                    } else if (verifyPin.status == 400) {
                        if (verifyPin.lock <= 0) {
                            this.errAlert(
                                `รหัสผ่านไม่ถูกต้อง<br>attemped ${verifyPin.attempted}/3`
                            );
                        } else {
                            this.errAlert(
                                `กรุณาลองใหม่อีกครั้ง<br> ภายในอีก ${verifyPin.lock} วินาที`
                            );
                        }
                    } else {
                        this.errAlert("ไม่พบรหัสผ่าน <br> กรุณา OTP ใหม่อีกครั้ง");
                    }
                } catch (err) {
                    console.log(err);
                    this.errAlert("กรุณาเช็คการเชื่อมต่อ");
                }
                this.loadingState = false;
            },
            confirmPinBack() {
                this.showSetPin = false;
                setTimeout(() => {
                    this.showConfirmPin = true;
                }, 200);
            },
            changePinReq() {
                localStorage.setItem("pinReq_casalapin", this.pinReq);
            },
            changeNotification() {
                localStorage.setItem("notification_casalapin", this.notificationReq);
            },
        },
        created() {
            this.pinReq = (localStorage.getItem('pinReq_casalapin') == 'true');;
            this.notificationReq = (localStorage.getItem('notification_casalapin') == 'true');
        },
        async beforeCreate() {
            await this.$store.dispatch("getBalance");
        }

    };
</script>

<style lang="scss">
    @import "@/assets/scss/color.scss";

    .setting-page {
        height: 100%;
        .card-setting:not(:last-child) {
            padding-bottom: 10px;
        }

        .card-setting {
            padding-left: 16px;
            padding-right: 16px;
            display: flex;
            justify-content: center;

            .setting-item {
                height: 56px;
                border-radius: 8px;
                background-color: $accent-color;
                max-width: 600px;

                .title-his {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1;
                }
            }

            .text-right {
                height: 100%;
                position: relative;
            }
        }
    }
</style>